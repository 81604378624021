.estate {
  max-width: 70ch;
  margin: 4rem auto 0 auto;
}

.estate__img {
  width: 100%;
}

.estate-header {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
}
