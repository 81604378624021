.splash-screen {
  width: 100vw;
  height: 100vh;
  display: grid;
  align-content: center;
  background-color: white;
  z-index: 50;
  position: relative;
}

.splash-screen__h1 {
  font-size: 8vw;
  text-align: center;
}
