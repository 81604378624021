.news {
  padding: 2em;
  padding-bottom: 5em;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
.news__h1 {
  text-align: center;
}
.news-articles-container {
  margin-top: 5em;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1200px;
  gap: 2em;
}

.news-article {
  max-width: 360px;
}

.news-article__img {
  width: 100%;
  height: 240px;
}

.news-article__h1 {
  font-size: 1.5rem;
}

.news-article-link {
  color: black;
}

@media screen and (max-width: 1000px) {
  .news-articles-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 800px) {
  .news-articles-container {
    grid-template-columns: 1fr;
  }
}

/* single news */
.single-news {
  margin: 4em auto 0 auto;
  padding: 1em;
  max-width: 50rem;
}

.single-news__img {
  max-width: 100%;
  border-radius: 5px;
}
