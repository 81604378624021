.about-header {
  display: grid;
  height: 95vh;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  padding: 5em;
}

.about-header__h1 {
  color: inherit;
  font-size: 6vw;
  line-height: 1em;
  margin: 0;
}

.about-header__p {
  max-width: 70ch;
}
.about-header__img {
  background: url("https://images.unsplash.com/flagged/photo-1574874897534-036671407eda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80")
    no-repeat 50% 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;

  object-position: center 23%;
}
.about-header__img--container {
  height: 50vh;
  position: relative;
  overflow: hidden;
  width: 50vw;
  min-width: 250px;
  max-width: 400px;
  margin-bottom: 1rem;
}

.about__main {
  padding-bottom: 5vh;
}

.about-paragraph {
  display: grid;
  grid-template-columns: 1fr 1fr;

  align-items: center;
  justify-items: center;
}

.about-paragraph--alternate .about-paragraph-text {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}
.about-paragraph--alternate .about-paragraph__img--wrapper {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.about-paragraph-text {
  padding: 5em;
  max-width: 80ch;
}

.about-paragraph__img--wrapper {
  /* position: absolute; */
  overflow: hidden;
}
.about-paragraph__img {
  /* position: absolute; */
  height: 100vh;
  width: 49.43vw;
  /* background-attachment: fixed; */
  background-position: center center;
  background-size: cover;
}

@media only screen and (max-width: 1100px) {
  .about-header {
    /* background-color: lightblue; */
    padding: 2em;
    grid-template-columns: 2fr 1fr;
    gap: 5vw;
  }
}

@media only screen and (max-width: 800px) {
  .about-header {
    grid-template-columns: 1fr;
    grid-template-rows: 75vh auto;
    padding: 0;
    min-height: 135vh;
  }
  .about-header--text {
    grid-row: 2/3;
    padding: 0 2em;
  }

  .about-header__img--container {
    grid-row: 1 / 2;
    height: 100%;
    width: 100vw;
    max-width: none;
  }
  .about-header__img {
    height: 100%;
    width: 100vw;
    border-radius: 0;
    object-position: 0 8%;
  }
  .about-header__h1 {
    max-width: none;
    font-size: 10vw;
  }
  .about-header__p {
    max-width: 50ch;
  }

  .about__main {
    padding-top: 10vh;
  }
  .about-paragraph {
    grid-template-columns: 1fr;
  }

  .about-paragraph--alternate .about-paragraph-text {
    grid-column: auto;
    grid-row: auto;
  }
  .about-paragraph--alternate .about-paragraph__img--wrapper {
    grid-column: auto;
    grid-row: auto;
  }

  .about-paragraph-text {
    padding: 10vw;
  }
  .about-paragraph__img {
    width: 90vw;
    height: 60vh;
  }
}
