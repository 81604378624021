/* import fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Raleway&display=swap");
@import url("./css/sanitize.css");

:root {
  --nav-ff: "Montserrat";
  /* main font */
  --ff: "Raleway", sans-serif;
  /* display font */
  --display-ff: "Montserrat", sans-serif;
  /* main color */
  /* --highlight: grey; */
}

body {
  min-height: 100vh;
}

h1,
h2,
h3,
h4 {
  font-family: var(--display-ff, "sans-serif");
  cursor: auto;
}
p,
label {
  font-family: var(--ff, "sans-serif");
  cursor: auto;
}
p {
  text-align: justify;
}
a {
  font-family: var(--display-ff, "sans-serif");
}

img {
  object-fit: cover;
}
