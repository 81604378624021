.contact {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 1fr 1fr;
}

.contact__img {
  width: 100%;
  height: 100%;
}
.contact-form {
  padding: 2em;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 70ch;
}

.contact-form input,
.contact-form textarea {
  padding: 0.5em;
  border-radius: 5px;
  border: 1px solid grey;
}

.contact-form button {
  padding: 0.5em 1em;
  background-color: #dcecff;
  border: none;
  border-radius: 5px;
}

@media screen and (min-width: 500px) {
  .contact {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .contact__img {
    max-width: 50vw;
  }
}
