.decision-wrapper {
  background-color: rgb(221, 221, 221);
  min-height: 100vh;
  min-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
}

.decision-sell,
.decision-advice,
.decision-buy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.decision-sell {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://images.unsplash.com/photo-1494526585095-c41746248156?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80");
  background-size: cover;
  background-position: center bottom;
}
.decision-advice {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://images.unsplash.com/photo-1511452885600-a3d2c9148a31?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1021&q=80");
  background-size: cover;
  background-position: center bottom;
}
.decision-buy {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://images.unsplash.com/photo-1554995207-c18c203602cb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80");
  background-size: cover;
  background-position: top center;
}

.decision-text-wrapper {
  margin-left: 3vw;
  max-width: 50ch;
}
.decision-text-wrapper > * {
  margin: 1rem;
}

/* for animation */
/* .decision__h1,
.decision__p,
.decision__cta {
  opacity: 0;
} */
.decision__h1 {
  color: whitesmoke;
}
.decision__p {
  color: rgb(246, 246, 246);
}

.decision__cta {
  display: block;
  width: fit-content;
  padding: 1em 2em;
  border: 2px solid white;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  transition: 500ms;
}

.decision__cta:hover,
.decision__cta:focus {
  background-color: white;
  border-color: transparent;
  color: black;
}

@media screen and (min-width: 1000px) {
  .decision-wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }

  .decision-sell,
  .decision-buy {
    align-items: center;
  }

  .decision-text-wrapper {
    min-height: 60vh;
  }
}
