.valuation {
  padding: 2em;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-items: center;
  height: 80vh;
  /* max-width: 1500px; */
  margin: 100px auto;
}

.valuation-left {
  width: 60%;
  max-width: 50ch;
}

.valuation-right {
  width: 100%;
  /* height: 100%; */
  /* background-image: url("../img/bvb-4.JPG"); */
  background-position: top center;
  background-size: cover;
}

.valuation-card {
  padding: 0.5em 1em;
  margin-bottom: 1em;
  border-radius: 5px;
  display: flex;
  align-items: center;

  background-color: rgb(252, 252, 252);
  border: solid 1px #cbcbcb;

  color: black;
  text-decoration: none;
}

.valuation-card:hover {
  box-shadow: 4px 6px 20px -9px rgb(225 225 225 / 75%);
  -webkit-box-shadow: 4px 6px 20px -9px rgb(225 225 225 / 75%);
  -moz-box-shadow: 4px 6px 20px -9px rgb(225 225 225 / 75%);
}

.valuation-card__p {
  margin-right: 5ch;
}
.valuation-card-arrow {
  margin-left: auto;
  height: 2em;
}

.valuation-arrow-back {
  height: 1.5em;
  transform: rotate(180deg);
}

/* valuation form */

.valuation-back {
  font-size: 0.7rem;
  color: black;
  margin: 0 auto;
}

.valuation-form input {
  padding: 0.5em 0.25em;
  width: 100%;
  display: block;
  margin-bottom: 1em;
}

.valuation-form input[type="submit"] {
  background-color: var(--highlight, #dcecff);
  font-size: 1rem;
  padding: 0.5em 1em;
  margin-top: 2em;
  border-radius: 5px;
  border: none;
}

.valuation-message {
  background-color: aquamarine;
  font-weight: bold;
  /* padding: 0.2em 1em; */
  border-radius: 5px;
}

@media screen and (max-width: 900px) {
  .valuation {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 50%;
    padding: 0;
    margin-top: 0;
    height: fit-content;
  }

  .valuation-left {
    width: 90%;
  }

  .valuation-right {
    position: relative;
    grid-row: 1/ 1;
    grid-column: 1/2;
    z-index: -1;
    border-radius: 0 0 -120%;
    min-height: 70vh;
  }

  .valuation-right::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1em;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: white;
  }

  .valuation-left {
    padding: 0.5em;
  }
}
