.connect {
  min-height: 100vh;
  width: 100%;

  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://images.unsplash.com/photo-1583459291453-f7c2b9fb98f9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80");
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;

  display: grid;
  align-items: center;
  justify-items: center;
}

.connect-work {
  padding: 3em;
  width: fit-content;
  max-width: min(90%, 70ch);
  height: fit-content;

  background: rgba(0, 0, 0, 0.51);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.9px);
  -webkit-backdrop-filter: blur(5.9px);
  border: 1px solid rgba(0, 0, 0, 0.31);
}

.connect__h1 {
  color: white;
  margin-top: 0;
}

.connect__p {
  color: rgb(217, 217, 217);
}

.connect__cta,
.connect__submit {
  padding: 0.5em 1em;
  border-radius: 5px;
  border: 2px solid white;
  color: white;
  display: block;
  margin-top: 1em;
  min-width: 100%;
  text-align: center;
}

.connect__cta {
  text-decoration: none;
}

.connect__cta:hover,
.connect__submit:hover {
  transition: 500ms;
  background-color: white;
  color: black;
}

.connect-news {
  padding: 3em;
  height: fit-content;
  background: rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.2px);
  -webkit-backdrop-filter: blur(2.2px);
  border: 1px solid rgba(0, 0, 0, 0.14);
}

.connect__submit,
.connect__input {
  padding: 10px;
  font-size: 17px;
}

.connect__input {
  display: inline-block;
  min-width: 100%;
  min-height: 45px;
  border: 1px solid black;
  border-radius: 5px;
}

.connect__submit {
  background-color: transparent;
}

@media screen and (min-width: 900px) {
  .connect {
    grid-template-columns: 1fr 1fr;
  }
  .connect-work {
    margin-right: -1em;
    z-index: 2;
    justify-self: end;
    padding: 4em 3em 4em 3em;
  }
  .connect__cta {
    min-width: auto;
    max-width: fit-content;
  }
  .connect-news {
    justify-self: baseline;
  }
}

/* Turn off parallax scrolling for all tablets and phones. */
@media only screen and (max-device-width: 1366px) {
  .connect {
    background-attachment: scroll;
  }
}
