.home--header {
  width: 100%;
}

.home video {
  background-image: url("https://images.unsplash.com/photo-1577052185469-4c277a9463a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2374&q=80");
  background-position: center;
  background-size: cover;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  filter: brightness(70%);
}

.home-header--text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  justify-items: center;

  z-index: 2;
}

.home-header__p,
.home-header__h1 {
  color: white;
  margin: 0;
}

.home-header__h1 {
  font-size: 3rem;
  margin-top: 0.125em;
}

.home-header--subtitle {
  font-family: var(--display-ff, "sans serif");
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: -1.5em;
}
.home-header--cta {
  margin-top: 0.5em;
  padding: 0.5em 1em;
  border-radius: 5px;
  border: 2px solid white;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  transition: 500ms;
}

.home-header--cta:hover,
.home-header--cta:focus {
  background-color: white;
  color: black;
  border-color: transparent;
}

/* about section */
.about {
  padding-top: 2em;
  /* animation */
  /* overflow-x: hidden; */
}

.about-text {
  padding: 2em;
  padding-bottom: 1em;
  min-height: 50vh;
}
.about__h1 {
  margin: 0;
}

.about__h2 {
  font-weight: 400;
  margin-top: 0.25em;
}

.about__p {
  max-width: 60ch;
  /* for animation */
  opacity: 0;
}
.about__cta {
  padding: 0.5em 1.5em;
  border: solid 2px black;
  border-radius: 5px;
  color: black;
  text-decoration: none;
  /* animtion */
  opacity: 0;
}

.about__cta:hover {
  transition: 500ms;
  background-color: black;
  color: white;
}

.about__img {
  width: 100%;
  height: 100vh;
  margin-left: auto;
  /* transform: translateX(100%); */
}

@media screen and (min-width: 700px) {
  .about {
    display: flex;
    padding-top: 0;
  }
  .about-text {
    padding-top: 10em;
  }
  .about__img {
    max-width: 45vw;
    mask-image: linear-gradient(90deg, transparent 10%, black 20%);
  }
  .home-header__h1 {
    font-size: 4em;
  }
  .home-header__p {
    font-size: 1.25em;
  }
}
