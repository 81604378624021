.top-nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.top-nav__ul,
.top-nav__li--container {
  display: flex;
}

.top-nav__ul {
  justify-content: space-between;
  list-style: none;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.top-nav__a {
  color: var(--nav-color, rgb(29, 29, 29));
  font-family: var(--nav-ff, "sans-serif");
  text-decoration: none;
  margin: 0 1em;
}

.top-nav__li--logo {
  font-weight: 500;
  color: var(--nav-logo-color, black);
}
.top-nav--hamburger::before,
.top-nav--hamburger::after,
.top-nav--hamburger {
  width: 1.8em;
  height: 0.2em;
  border-radius: 2px;
  background-color: var(--nav-color, rgb(29, 29, 29));
}

.top-nav--hamburger {
  position: relative;
  /* hode for bigger screen sizes */
  display: none;
  --hamburger-displacement: 0.45em;
  margin: 0.42em 1em;
}

.top-nav--hamburger::before,
.top-nav--hamburger::after {
  content: "";
  position: absolute;
}

.top-nav--hamburger::before {
  bottom: var(--hamburger-displacement, 0.5em);
}
.top-nav--hamburger::after {
  top: var(--hamburger-displacement, 0.5em);
}

.top-nav--hamburger-active {
  position: absolute;
  top: 1em;
  right: 0;
}
.top-nav--hamburger-active::before,
.top-nav--hamburger-active::after {
  transition: 250ms;
  top: 0.01em;
}

.top-nav--hamburger-active::before {
  transform: rotate(-45deg);
}
.top-nav--hamburger-active::after {
  transform: rotate(45deg);
  background-color: var(--nav-color, rgb(29, 29, 29));
}

.top-nav__li--container.top-nav--hamburger-active {
  /* display: grid; */
  width: fit-content;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  padding: 4em 1em;
  flex-direction: column;
  background-color: hsl(0, 0%, 97%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
}
.top-nav__li--container.top-nav--hamburger-active .top-nav__li {
  display: block;
  padding: 0.5em 0;
}

@media screen and (max-width: 600px) {
  .top-nav__li {
    display: none;
  }

  .top-nav__a,
  .top-nav--hamburger-active {
    color: var(--nav-color-inverted, black);
  }
  .top-nav--hamburger.top-nav--hamburger-active::before,
  .top-nav--hamburger.top-nav--hamburger-active::after {
    background-color: var(--nav-color-inverted, rgb(29, 29, 29));
  }
  .top-nav--hamburger.top-nav--hamburger-active {
    background-color: transparent;
  }
  .top-nav--hamburger,
  .top-nav--logo {
    display: block;
  }

  .top-nav__li--logo {
    color: var(--nav-logo-color, black);
  }
}
